import "./NameAnimation.scss";

import React from "react";

function NameAnimation() {
  return (
    <div className="nameAnimation">
      <div class="nameAnimation-large d-none d-lg-block">
        <svg
          className="animatesvg"
          id="logo"
          width="881"
          height="90"
          viewBox="0 0 881 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="path-1-outside-1"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="881"
            height="90"
            fill="black"
          >
            <rect fill="white" width="881" height="90" />
            <path d="M32.751 84.143C27.645 84.143 23.057 83.255 18.987 81.479C14.991 79.629 11.846 77.113 9.552 73.931C7.258 70.675 6.074 66.938 6 62.72H16.767C17.137 66.346 18.617 69.417 21.207 71.933C23.871 74.375 27.719 75.596 32.751 75.596C37.561 75.596 41.335 74.412 44.073 72.044C46.885 69.602 48.291 66.494 48.291 62.72C48.291 59.76 47.477 57.355 45.849 55.505C44.221 53.655 42.186 52.249 39.744 51.287C37.302 50.325 34.009 49.289 29.865 48.179C24.759 46.847 20.652 45.515 17.544 44.183C14.51 42.851 11.883 40.779 9.663 37.967C7.517 35.081 6.444 31.233 6.444 26.423C6.444 22.205 7.517 18.468 9.663 15.212C11.809 11.956 14.806 9.44 18.654 7.664C22.576 5.888 27.053 5 32.085 5C39.337 5 45.257 6.813 49.845 10.439C54.507 14.065 57.134 18.875 57.726 24.869H46.626C46.256 21.909 44.702 19.319 41.964 17.099C39.226 14.805 35.6 13.658 31.086 13.658C26.868 13.658 23.427 14.768 20.763 16.988C18.099 19.134 16.767 22.168 16.767 26.09C16.767 28.902 17.544 31.196 19.098 32.972C20.726 34.748 22.687 36.117 24.981 37.079C27.349 37.967 30.642 39.003 34.86 40.187C39.966 41.593 44.073 42.999 47.181 44.405C50.289 45.737 52.953 47.846 55.173 50.732C57.393 53.544 58.503 57.392 58.503 62.276C58.503 66.05 57.504 69.602 55.506 72.932C53.508 76.262 50.548 78.963 46.626 81.035C42.704 83.107 38.079 84.143 32.751 84.143Z" />
            <path d="M90.1285 5.999V83.366H80.0275V5.999H90.1285Z" />
            <path d="M138.091 5.999C146.527 5.999 153.816 7.59 159.958 10.772C166.174 13.88 170.91 18.357 174.166 24.203C177.496 30.049 179.161 36.931 179.161 44.849C179.161 52.767 177.496 59.649 174.166 65.495C170.91 71.267 166.174 75.707 159.958 78.815C153.816 81.849 146.527 83.366 138.091 83.366H114.004V5.999H138.091ZM138.091 75.041C148.081 75.041 155.703 72.414 160.957 67.16C166.211 61.832 168.838 54.395 168.838 44.849C168.838 35.229 166.174 27.718 160.846 22.316C155.592 16.914 148.007 14.213 138.091 14.213H124.105V75.041H138.091Z" />
            <path d="M223.231 5.999C231.667 5.999 238.956 7.59 245.098 10.772C251.314 13.88 256.05 18.357 259.306 24.203C262.636 30.049 264.301 36.931 264.301 44.849C264.301 52.767 262.636 59.649 259.306 65.495C256.05 71.267 251.314 75.707 245.098 78.815C238.956 81.849 231.667 83.366 223.231 83.366H199.144V5.999H223.231ZM223.231 75.041C233.221 75.041 240.843 72.414 246.097 67.16C251.351 61.832 253.978 54.395 253.978 44.849C253.978 35.229 251.314 27.718 245.986 22.316C240.732 16.914 233.147 14.213 223.231 14.213H209.245V75.041H223.231Z" />
            <path d="M343.892 5.999V83.366H333.791V48.29H294.386V83.366H284.285V5.999H294.386V39.965H333.791V5.999H343.892Z" />
            <path d="M413.531 66.161H379.787L373.571 83.366H362.915L390.887 6.443H402.542L430.403 83.366H419.747L413.531 66.161ZM410.645 57.947L396.659 18.875L382.673 57.947H410.645Z" />
            <path d="M489.991 83.366L471.565 51.731H459.355V83.366H449.254V5.999H474.229C480.075 5.999 484.996 6.998 488.992 8.996C493.062 10.994 496.096 13.695 498.094 17.099C500.092 20.503 501.091 24.388 501.091 28.754C501.091 34.082 499.537 38.781 496.429 42.851C493.395 46.921 488.807 49.622 482.665 50.954L502.09 83.366H489.991ZM459.355 43.628H474.229C479.705 43.628 483.812 42.296 486.55 39.632C489.288 36.894 490.657 33.268 490.657 28.754C490.657 24.166 489.288 20.614 486.55 18.098C483.886 15.582 479.779 14.324 474.229 14.324H459.355V43.628Z" />
            <path d="M571.065 5.999V14.213H549.975V83.366H539.874V14.213H518.673V5.999H571.065Z" />
            <path d="M649.766 5.999V83.366H639.665V48.29H600.26V83.366H590.159V5.999H600.26V39.965H639.665V5.999H649.766Z" />
            <path d="M750.663 83.366L732.237 51.731H720.027V83.366H709.926V5.999H734.901C740.747 5.999 745.668 6.998 749.664 8.996C753.734 10.994 756.768 13.695 758.766 17.099C760.764 20.503 761.763 24.388 761.763 28.754C761.763 34.082 760.209 38.781 757.101 42.851C754.067 46.921 749.479 49.622 743.337 50.954L762.762 83.366H750.663ZM720.027 43.628H734.901C740.377 43.628 744.484 42.296 747.222 39.632C749.96 36.894 751.329 33.268 751.329 28.754C751.329 24.166 749.96 20.614 747.222 18.098C744.558 15.582 740.451 14.324 734.901 14.324H720.027V43.628Z" />
            <path d="M829.851 66.161H796.107L789.891 83.366H779.235L807.207 6.443H818.862L846.723 83.366H836.067L829.851 66.161ZM826.965 57.947L812.979 18.875L798.993 57.947H826.965Z" />
            <path d="M875.675 5.999V83.366H865.574V5.999H875.675Z" />
          </mask>
          <path
            d="M32.751 84.143C27.645 84.143 23.057 83.255 18.987 81.479C14.991 79.629 11.846 77.113 9.552 73.931C7.258 70.675 6.074 66.938 6 62.72H16.767C17.137 66.346 18.617 69.417 21.207 71.933C23.871 74.375 27.719 75.596 32.751 75.596C37.561 75.596 41.335 74.412 44.073 72.044C46.885 69.602 48.291 66.494 48.291 62.72C48.291 59.76 47.477 57.355 45.849 55.505C44.221 53.655 42.186 52.249 39.744 51.287C37.302 50.325 34.009 49.289 29.865 48.179C24.759 46.847 20.652 45.515 17.544 44.183C14.51 42.851 11.883 40.779 9.663 37.967C7.517 35.081 6.444 31.233 6.444 26.423C6.444 22.205 7.517 18.468 9.663 15.212C11.809 11.956 14.806 9.44 18.654 7.664C22.576 5.888 27.053 5 32.085 5C39.337 5 45.257 6.813 49.845 10.439C54.507 14.065 57.134 18.875 57.726 24.869H46.626C46.256 21.909 44.702 19.319 41.964 17.099C39.226 14.805 35.6 13.658 31.086 13.658C26.868 13.658 23.427 14.768 20.763 16.988C18.099 19.134 16.767 22.168 16.767 26.09C16.767 28.902 17.544 31.196 19.098 32.972C20.726 34.748 22.687 36.117 24.981 37.079C27.349 37.967 30.642 39.003 34.86 40.187C39.966 41.593 44.073 42.999 47.181 44.405C50.289 45.737 52.953 47.846 55.173 50.732C57.393 53.544 58.503 57.392 58.503 62.276C58.503 66.05 57.504 69.602 55.506 72.932C53.508 76.262 50.548 78.963 46.626 81.035C42.704 83.107 38.079 84.143 32.751 84.143Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M90.1285 5.999V83.366H80.0275V5.999H90.1285Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M138.091 5.999C146.527 5.999 153.816 7.59 159.958 10.772C166.174 13.88 170.91 18.357 174.166 24.203C177.496 30.049 179.161 36.931 179.161 44.849C179.161 52.767 177.496 59.649 174.166 65.495C170.91 71.267 166.174 75.707 159.958 78.815C153.816 81.849 146.527 83.366 138.091 83.366H114.004V5.999H138.091ZM138.091 75.041C148.081 75.041 155.703 72.414 160.957 67.16C166.211 61.832 168.838 54.395 168.838 44.849C168.838 35.229 166.174 27.718 160.846 22.316C155.592 16.914 148.007 14.213 138.091 14.213H124.105V75.041H138.091Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M223.231 5.999C231.667 5.999 238.956 7.59 245.098 10.772C251.314 13.88 256.05 18.357 259.306 24.203C262.636 30.049 264.301 36.931 264.301 44.849C264.301 52.767 262.636 59.649 259.306 65.495C256.05 71.267 251.314 75.707 245.098 78.815C238.956 81.849 231.667 83.366 223.231 83.366H199.144V5.999H223.231ZM223.231 75.041C233.221 75.041 240.843 72.414 246.097 67.16C251.351 61.832 253.978 54.395 253.978 44.849C253.978 35.229 251.314 27.718 245.986 22.316C240.732 16.914 233.147 14.213 223.231 14.213H209.245V75.041H223.231Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M343.892 5.999V83.366H333.791V48.29H294.386V83.366H284.285V5.999H294.386V39.965H333.791V5.999H343.892Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M413.531 66.161H379.787L373.571 83.366H362.915L390.887 6.443H402.542L430.403 83.366H419.747L413.531 66.161ZM410.645 57.947L396.659 18.875L382.673 57.947H410.645Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M489.991 83.366L471.565 51.731H459.355V83.366H449.254V5.999H474.229C480.075 5.999 484.996 6.998 488.992 8.996C493.062 10.994 496.096 13.695 498.094 17.099C500.092 20.503 501.091 24.388 501.091 28.754C501.091 34.082 499.537 38.781 496.429 42.851C493.395 46.921 488.807 49.622 482.665 50.954L502.09 83.366H489.991ZM459.355 43.628H474.229C479.705 43.628 483.812 42.296 486.55 39.632C489.288 36.894 490.657 33.268 490.657 28.754C490.657 24.166 489.288 20.614 486.55 18.098C483.886 15.582 479.779 14.324 474.229 14.324H459.355V43.628Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M571.065 5.999V14.213H549.975V83.366H539.874V14.213H518.673V5.999H571.065Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M649.766 5.999V83.366H639.665V48.29H600.26V83.366H590.159V5.999H600.26V39.965H639.665V5.999H649.766Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M750.663 83.366L732.237 51.731H720.027V83.366H709.926V5.999H734.901C740.747 5.999 745.668 6.998 749.664 8.996C753.734 10.994 756.768 13.695 758.766 17.099C760.764 20.503 761.763 24.388 761.763 28.754C761.763 34.082 760.209 38.781 757.101 42.851C754.067 46.921 749.479 49.622 743.337 50.954L762.762 83.366H750.663ZM720.027 43.628H734.901C740.377 43.628 744.484 42.296 747.222 39.632C749.96 36.894 751.329 33.268 751.329 28.754C751.329 24.166 749.96 20.614 747.222 18.098C744.558 15.582 740.451 14.324 734.901 14.324H720.027V43.628Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M829.851 66.161H796.107L789.891 83.366H779.235L807.207 6.443H818.862L846.723 83.366H836.067L829.851 66.161ZM826.965 57.947L812.979 18.875L798.993 57.947H826.965Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M875.675 5.999V83.366H865.574V5.999H875.675Z"
            stroke="white"
            stroke-width="10"
            mask="url(#path-1-outside-1)"
          />
        </svg>
      </div>

      <div class="nameAnimation-phone d-lg-none">
        <svg
          id="logo-phone"
          width="355"
          height="129"
          viewBox="0 0 355 129"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="path-1-outside-1"
            maskUnits="userSpaceOnUse"
            x="0.0761719"
            y="0.433594"
            width="355"
            height="129"
            fill="black"
          >
            <rect
              fill="white"
              x="0.0761719"
              y="0.433594"
              width="355"
              height="129"
            />
            <path d="M18.7695 29.085C13.4629 27.5596 9.5957 25.6904 7.16797 23.4775C4.76172 21.2432 3.55859 18.4932 3.55859 15.2275C3.55859 11.5322 5.03027 8.48145 7.97363 6.0752C10.9385 3.64746 14.7842 2.43359 19.5107 2.43359C22.7334 2.43359 25.6016 3.05664 28.1152 4.30273C30.6504 5.54883 32.6055 7.26758 33.9805 9.45898C35.377 11.6504 36.0752 14.0459 36.0752 16.6455H29.8555C29.8555 13.8096 28.9531 11.5859 27.1484 9.97461C25.3438 8.3418 22.7979 7.52539 19.5107 7.52539C16.46 7.52539 14.0752 8.20215 12.3564 9.55566C10.6592 10.8877 9.81055 12.7461 9.81055 15.1309C9.81055 17.043 10.6162 18.665 12.2275 19.9971C13.8604 21.3076 16.6211 22.5107 20.5098 23.6064C24.4199 24.7021 27.4707 25.916 29.6621 27.248C31.875 28.5586 33.5078 30.0947 34.5605 31.8564C35.6348 33.6182 36.1719 35.6914 36.1719 38.0762C36.1719 41.8789 34.6895 44.9297 31.7246 47.2285C28.7598 49.5059 24.7959 50.6445 19.833 50.6445C16.6104 50.6445 13.6025 50.0322 10.8096 48.8076C8.0166 47.5615 5.85742 45.8643 4.33203 43.7158C2.82812 41.5674 2.07617 39.1289 2.07617 36.4004H8.2959C8.2959 39.2363 9.33789 41.4814 11.4219 43.1357C13.5273 44.7686 16.3311 45.585 19.833 45.585C23.0986 45.585 25.6016 44.9189 27.3418 43.5869C29.082 42.2549 29.9521 40.4395 29.9521 38.1406C29.9521 35.8418 29.1465 34.0693 27.5352 32.8232C25.9238 31.5557 23.002 30.3096 18.7695 29.085Z" />
            <path d="M50.7705 50H44.583V3.07812H50.7705V50Z" />
            <path d="M62.1143 50V3.07812H75.3594C79.4414 3.07812 83.0508 3.98047 86.1875 5.78516C89.3242 7.58984 91.7412 10.1572 93.4385 13.4873C95.1572 16.8174 96.0273 20.6416 96.0488 24.96V27.957C96.0488 32.3828 95.1895 36.2607 93.4707 39.5908C91.7734 42.9209 89.335 45.4775 86.1553 47.2607C82.9971 49.0439 79.3125 49.957 75.1016 50H62.1143ZM68.3018 8.16992V44.9404H74.8115C79.5811 44.9404 83.2871 43.458 85.9297 40.4932C88.5938 37.5283 89.9258 33.3066 89.9258 27.8281V25.0889C89.9258 19.7607 88.6689 15.625 86.1553 12.6816C83.6631 9.7168 80.1182 8.21289 75.5205 8.16992H68.3018Z" />
            <path d="M105.427 50V3.07812H118.672C122.754 3.07812 126.363 3.98047 129.5 5.78516C132.637 7.58984 135.054 10.1572 136.751 13.4873C138.47 16.8174 139.34 20.6416 139.361 24.96V27.957C139.361 32.3828 138.502 36.2607 136.783 39.5908C135.086 42.9209 132.647 45.4775 129.468 47.2607C126.31 49.0439 122.625 49.957 118.414 50H105.427ZM111.614 8.16992V44.9404H118.124C122.894 44.9404 126.6 43.458 129.242 40.4932C131.906 37.5283 133.238 33.3066 133.238 27.8281V25.0889C133.238 19.7607 131.981 15.625 129.468 12.6816C126.976 9.7168 123.431 8.21289 118.833 8.16992H111.614Z" />
            <path d="M184.801 50H178.581V28.3115H154.927V50H148.739V3.07812H154.927V23.252H178.581V3.07812H184.801V50Z" />
            <path d="M222.28 37.7539H202.622L198.207 50H191.826L209.744 3.07812H215.158L233.108 50H226.76L222.28 37.7539ZM204.491 32.6621H220.443L212.451 10.7158L204.491 32.6621Z" />
            <path d="M256.634 31.0186H245.612V50H239.393V3.07812H254.926C260.211 3.07812 264.271 4.28125 267.107 6.6875C269.965 9.09375 271.394 12.5957 271.394 17.1934C271.394 20.1152 270.599 22.6611 269.009 24.8311C267.44 27.001 265.249 28.623 262.435 29.6973L273.456 49.6133V50H266.817L256.634 31.0186ZM245.612 25.959H255.119C258.191 25.959 260.63 25.1641 262.435 23.5742C264.261 21.9844 265.174 19.8574 265.174 17.1934C265.174 14.293 264.304 12.0693 262.563 10.5225C260.845 8.97559 258.353 8.19141 255.087 8.16992H245.612V25.959Z" />
            <path d="M309.937 8.16992H294.854V50H288.699V8.16992H273.649V3.07812H309.937V8.16992Z" />
            <path d="M352.959 50H346.739V28.3115H323.085V50H316.897V3.07812H323.085V23.252H346.739V3.07812H352.959V50Z" />
            <path d="M150.802 108.019H139.78V127H133.561V80.0781H149.094C154.379 80.0781 158.439 81.2812 161.275 83.6875C164.133 86.0938 165.562 89.5957 165.562 94.1934C165.562 97.1152 164.767 99.6611 163.177 101.831C161.608 104.001 159.417 105.623 156.603 106.697L167.624 126.613V127H160.985L150.802 108.019ZM139.78 102.959H149.287C152.359 102.959 154.798 102.164 156.603 100.574C158.429 98.9844 159.342 96.8574 159.342 94.1934C159.342 91.293 158.472 89.0693 156.731 87.5225C155.013 85.9756 152.521 85.1914 149.255 85.1699H139.78V102.959Z" />
            <path d="M200.173 114.754H180.515L176.1 127H169.719L187.637 80.0781H193.051L211.001 127H204.652L200.173 114.754ZM182.384 109.662H198.336L190.344 87.7158L182.384 109.662Z" />
            <path d="M223.956 127H217.769V80.0781H223.956V127Z" />
          </mask>
          <path
            d="M18.7695 29.085C13.4629 27.5596 9.5957 25.6904 7.16797 23.4775C4.76172 21.2432 3.55859 18.4932 3.55859 15.2275C3.55859 11.5322 5.03027 8.48145 7.97363 6.0752C10.9385 3.64746 14.7842 2.43359 19.5107 2.43359C22.7334 2.43359 25.6016 3.05664 28.1152 4.30273C30.6504 5.54883 32.6055 7.26758 33.9805 9.45898C35.377 11.6504 36.0752 14.0459 36.0752 16.6455H29.8555C29.8555 13.8096 28.9531 11.5859 27.1484 9.97461C25.3438 8.3418 22.7979 7.52539 19.5107 7.52539C16.46 7.52539 14.0752 8.20215 12.3564 9.55566C10.6592 10.8877 9.81055 12.7461 9.81055 15.1309C9.81055 17.043 10.6162 18.665 12.2275 19.9971C13.8604 21.3076 16.6211 22.5107 20.5098 23.6064C24.4199 24.7021 27.4707 25.916 29.6621 27.248C31.875 28.5586 33.5078 30.0947 34.5605 31.8564C35.6348 33.6182 36.1719 35.6914 36.1719 38.0762C36.1719 41.8789 34.6895 44.9297 31.7246 47.2285C28.7598 49.5059 24.7959 50.6445 19.833 50.6445C16.6104 50.6445 13.6025 50.0322 10.8096 48.8076C8.0166 47.5615 5.85742 45.8643 4.33203 43.7158C2.82812 41.5674 2.07617 39.1289 2.07617 36.4004H8.2959C8.2959 39.2363 9.33789 41.4814 11.4219 43.1357C13.5273 44.7686 16.3311 45.585 19.833 45.585C23.0986 45.585 25.6016 44.9189 27.3418 43.5869C29.082 42.2549 29.9521 40.4395 29.9521 38.1406C29.9521 35.8418 29.1465 34.0693 27.5352 32.8232C25.9238 31.5557 23.002 30.3096 18.7695 29.085Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M50.7705 50H44.583V3.07812H50.7705V50Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M62.1143 50V3.07812H75.3594C79.4414 3.07812 83.0508 3.98047 86.1875 5.78516C89.3242 7.58984 91.7412 10.1572 93.4385 13.4873C95.1572 16.8174 96.0273 20.6416 96.0488 24.96V27.957C96.0488 32.3828 95.1895 36.2607 93.4707 39.5908C91.7734 42.9209 89.335 45.4775 86.1553 47.2607C82.9971 49.0439 79.3125 49.957 75.1016 50H62.1143ZM68.3018 8.16992V44.9404H74.8115C79.5811 44.9404 83.2871 43.458 85.9297 40.4932C88.5938 37.5283 89.9258 33.3066 89.9258 27.8281V25.0889C89.9258 19.7607 88.6689 15.625 86.1553 12.6816C83.6631 9.7168 80.1182 8.21289 75.5205 8.16992H68.3018Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M105.427 50V3.07812H118.672C122.754 3.07812 126.363 3.98047 129.5 5.78516C132.637 7.58984 135.054 10.1572 136.751 13.4873C138.47 16.8174 139.34 20.6416 139.361 24.96V27.957C139.361 32.3828 138.502 36.2607 136.783 39.5908C135.086 42.9209 132.647 45.4775 129.468 47.2607C126.31 49.0439 122.625 49.957 118.414 50H105.427ZM111.614 8.16992V44.9404H118.124C122.894 44.9404 126.6 43.458 129.242 40.4932C131.906 37.5283 133.238 33.3066 133.238 27.8281V25.0889C133.238 19.7607 131.981 15.625 129.468 12.6816C126.976 9.7168 123.431 8.21289 118.833 8.16992H111.614Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M184.801 50H178.581V28.3115H154.927V50H148.739V3.07812H154.927V23.252H178.581V3.07812H184.801V50Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M222.28 37.7539H202.622L198.207 50H191.826L209.744 3.07812H215.158L233.108 50H226.76L222.28 37.7539ZM204.491 32.6621H220.443L212.451 10.7158L204.491 32.6621Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M256.634 31.0186H245.612V50H239.393V3.07812H254.926C260.211 3.07812 264.271 4.28125 267.107 6.6875C269.965 9.09375 271.394 12.5957 271.394 17.1934C271.394 20.1152 270.599 22.6611 269.009 24.8311C267.44 27.001 265.249 28.623 262.435 29.6973L273.456 49.6133V50H266.817L256.634 31.0186ZM245.612 25.959H255.119C258.191 25.959 260.63 25.1641 262.435 23.5742C264.261 21.9844 265.174 19.8574 265.174 17.1934C265.174 14.293 264.304 12.0693 262.563 10.5225C260.845 8.97559 258.353 8.19141 255.087 8.16992H245.612V25.959Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M309.937 8.16992H294.854V50H288.699V8.16992H273.649V3.07812H309.937V8.16992Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M352.959 50H346.739V28.3115H323.085V50H316.897V3.07812H323.085V23.252H346.739V3.07812H352.959V50Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M150.802 108.019H139.78V127H133.561V80.0781H149.094C154.379 80.0781 158.439 81.2812 161.275 83.6875C164.133 86.0938 165.562 89.5957 165.562 94.1934C165.562 97.1152 164.767 99.6611 163.177 101.831C161.608 104.001 159.417 105.623 156.603 106.697L167.624 126.613V127H160.985L150.802 108.019ZM139.78 102.959H149.287C152.359 102.959 154.798 102.164 156.603 100.574C158.429 98.9844 159.342 96.8574 159.342 94.1934C159.342 91.293 158.472 89.0693 156.731 87.5225C155.013 85.9756 152.521 85.1914 149.255 85.1699H139.78V102.959Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M200.173 114.754H180.515L176.1 127H169.719L187.637 80.0781H193.051L211.001 127H204.652L200.173 114.754ZM182.384 109.662H198.336L190.344 87.7158L182.384 109.662Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M223.956 127H217.769V80.0781H223.956V127Z"
            stroke="#FEFEFE"
            stroke-width="4"
            mask="url(#path-1-outside-1)"
          />
        </svg>
      </div>
    </div>
  );
}

export default NameAnimation;
